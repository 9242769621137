<template>
    <div class="mb-24">
        <Payment
            :application="application"
            :property="{ id: propertyId }"
        ></Payment>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Payment from '@/components/applicationService/Payment.vue';

export default {
    name: 'ApplicationPayment',
    components: { Payment },
    data() {
        return {
            application: null,
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
        }),

        propertyId() {
            return this.$route.params.propertyId || '';
        },
    },

    beforeMount() {
        if (this.getApplication) this.application = this.getApplication;
    },
};
</script>
